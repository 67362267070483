import { Injectable } from '@angular/core';
import jsonVersiones from '../../assets/json/versiones.json';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  postId: string          = null;
  entrada: boolean        = null;
  activarSidebar: string  = 'false';
  editar: string          = "aqui";

  // DECLARAR ENTORNO distinto a "local" para producción o liberación
  private entorno: string             = "PROD";
  // ****

  private dominio: string;
  public php: string                  = '/assets/php';
  public php_img: string;
  public php_download: string;
  public apiDiscovery: string         = "/discovery-sipa.php?";
  public phpDiscovery: string;
  public apiDiscovery2: string        = "/discovery.php?";
  public phpDiscovery2: string;
  public apiEmail: string             = "/mail-contacto/envio.php?";
  public phpEmail: string;
  public apiFicha: string             = "/ficha.php?";
  public phpFicha: string;
  public apiLoginCas: string          = '/cas-log/cas-login.php';
  public urlLoginCas: string;
  public apiVincular: string          = '/vinculacion/VincularSipaWos.php';
  public phpVincular: string;
  public apiGuardar: string           = '/vinculacion/mis_publicaciones.php';
  public phpGuardar: string;
  public apiDescargar: string         = '/vinculacion/descarga.php';
  public phpDescargar: string;
  public apiTitAlt: string            = '/vinculacion/titulo-alternativo.php';
  public phpTitAlt: string;
  public apiRegistrar: string         = '/adjuntos/adjunto.php';
  public phpRegistrar: string;
  public apiFormulario:string         = "/formulario-sipa/formulario.php"; 
  public phpFormulario:string;
  public apiSql: string               = "/formulario-sipa/sql.php"; 
  public phpSql: string;
  public apiPubliAcademicos: string   = "/jefatura/pub-academicos.php"; 
  public phpPubliAcademicos: string;
  public apiGenerarExcel: string      = "/jefatura/generar-excel.php";
  public phpGenerarExcel: string;
  public apiDescargarExcel: string    = "/jefatura/descargar-excel.php?archivo=";
  public phpDescargarExcel: string;
  public apiSqlArte: string           = "/formulario-sipa/sqlArtes.php"; 
  public phpSqlArte: string;
  public apiLanding: string           = "/api-mailchimp/landing.php?page=";
  public phpLanding: string;
  public apiLandingPage: string       = "/api-mailchimp/landing-page.php";
  public phpLandingPage: string;
  public apiSuscribirNews: string     = "/api-mailchimp/suscribir-newsletter.php?email=";
  public phpSuscribirNews: string;
  public apiUltimosAgregados: string  = "/ultimos-agregados.php?";
  public phpUltimosAgregados: string;
  public apiUploadFile: string        = "/autoarchivo/subirArchivo.php";
  public phpUploadFile: string;
  private apiCantidadArticulos:string = '/cantidad-articulos.php';
  public phpCantidadArticulos: string;
  private apiCantidadCapLibros:string = '/cantidad-capituloDeLibros.php';
  public phpCantidadCapLibros: string;
  private apiCantidadLibros: string   = '/cantidad-libros.php';
  public phpCantidadLibros: string;
  private apiCantidadPatentes: string = '/cantidad-patentes.php';
  public phpCantidadPatentes: string;
  private apiCantidadObras: string    = '/cantidad-obras.php';
  public phpCantidadObras: string;
  public apiHandle: string            = '/handle/';
  public urlHandle: string;
  public jsonVersiones:any = jsonVersiones;
  public versionPortal:any = [];

  constructor() { }

  public urls(){
    if(this.entorno != "local"){
      this.dominio              = window.location.protocol +'//'+window.location.hostname;
      this.php_img              = '/xmlui/bitstream/handle';
      this.php_download         = '/xmlui/bitstream/handle';
      
    }else{
      this.dominio              = "https://sipa-des.cloud.uc.cl";
      this.php_img              = ':8080/bitstream/handle';
      this.php_download         = ':8080/bitstream/handle';
    }
      this.php_img              = this.dominio + this.php_img;
      this.php_download         = this.dominio + this.php_download;
      this.phpDiscovery         = this.dominio + this.php + this.apiDiscovery;
      this.phpDiscovery2        = this.dominio + this.php + this.apiDiscovery2;
      this.phpEmail             = this.dominio + this.php + this.apiEmail ;
      this.phpFicha             = this.dominio + this.php + this.apiFicha;
      this.urlLoginCas          = this.dominio + this.php + this.apiLoginCas;
      this.phpVincular          = this.dominio + this.php + this.apiVincular;
      this.phpGuardar           = this.dominio + this.php + this.apiGuardar;
      this.phpDescargar         = this.dominio + this.php + this.apiDescargar;
      this.phpTitAlt            = this.dominio + this.php + this.apiTitAlt;
      this.phpRegistrar         = this.dominio + this.php + this.apiRegistrar;
      this.phpFormulario        = this.dominio + this.php + this.apiFormulario; 
      this.phpSql               = this.dominio + this.php + this.apiSql; 
      this.phpPubliAcademicos   = this.dominio + this.php + this.apiPubliAcademicos; 
      this.phpGenerarExcel      = this.dominio + this.php + this.apiGenerarExcel;
      this.phpDescargarExcel    = this.dominio + this.php + this.apiDescargarExcel;
      this.phpSqlArte           = this.dominio + this.php + this.apiSqlArte; 
      this.phpLanding           = this.dominio + this.php + this.apiLanding;
      this.phpLandingPage       = this.dominio + this.php + this.apiLandingPage;
      this.phpSuscribirNews     = this.dominio + this.php + this.apiSuscribirNews;
      this.phpUltimosAgregados  = this.dominio + this.php + this.apiUltimosAgregados;
      this.phpUploadFile        = this.dominio + this.php + this.apiUploadFile;
      this.phpCantidadArticulos = this.dominio + this.php + this.apiCantidadArticulos;
      this.phpCantidadCapLibros = this.dominio + this.php + this.apiCantidadCapLibros;
      this.phpCantidadLibros    = this.dominio + this.php + this.apiCantidadLibros;
      this.phpCantidadPatentes  = this.dominio + this.php + this.apiCantidadPatentes;
      this.phpCantidadObras     = this.dominio + this.php + this.apiCantidadObras;
      this.urlHandle            = this.dominio + this.apiHandle;
  }
  public login(){
    if(this.entorno != "local"){
      window.location.href='assets/php/cas-log/cas-login.php';
    }else{
      localStorage.setItem('usuario', 'Pantoja, Rodrigo');
      localStorage.setItem('correo', 'rodrigo.pantoja@uc.cl');
      localStorage.setItem('estado_user_sipa', 'true');
      localStorage.setItem('jsonAcademico', 
      '{"NOMBRE_COMPLETO": "RODRIGO PANTOJA","CATEGORIA_ACADEMICA": "PROFESOR ASISTENTE","COD_PERS": "1105052","CORREO": "rodrigo.pantoja@uc.cl","DV": "K","FACULTAD": "FACULTAD DE MEDICINA","RUT":"11.111.111-1","GRADO_PERFECCIONAMIENTO":"ESPECIALIDAD MÉDICA/ ODONTOLÓGICA","PAIS":"Chile","ORCID":"123456789","INSTITUCION":"PUC","UNIDAD_ACADEMICA":"DISEÑO","NOMBRE_PERFIL":["jefaturas"] }');    
      var d1:any = new Date().getTime();
      localStorage.setItem("sessionsipa", d1); 
    }
    
  }
  public logoutCas(){
    localStorage.removeItem('usuario');
    localStorage.removeItem('correo');
    localStorage.removeItem('estado_user_sipa');
    localStorage.removeItem('jsonAcademico');
    window.location.href='assets/php/cas-log/logout-cas.php';
  }
  public versionesPortal(){
    this.versionPortal = jsonVersiones;
    for(var i = 0; i < jsonVersiones.length; i++){
      this.versionPortal[i].version = this.jsonVersiones[i].numero_version + "." + this.jsonVersiones[i].numero_mejora_version + "." + this.jsonVersiones[i].numero_item_mejora + "-" + this.jsonVersiones[i].numero_rama
    }
  }
}
