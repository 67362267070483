import { Component, OnInit, ChangeDetectionStrategy, DoCheck, ɵConsole, Output }  from '@angular/core';
import { Router, ActivatedRoute }              from '@angular/router';
import json                                    from '../../../assets/json/upload-multipub/upload-multipub.json';
import { FileuploadService }                   from '../../servicio/fileupload.service';
import { QueriesService }                      from '../../services/queries.service';
import { HttpClient }                          from '@angular/common/http';
import { DataService }                         from 'src/app/services/data.service.js';

@Component({
  selector: 'app-upload-multipub',
  templateUrl: './upload-multipub.component.html',
  styleUrls: ['./upload-multipub.component.css']
})
export class UploadMultipubComponent implements OnInit, DoCheck {
  public datos_academicos = JSON.parse(this._queriesService.getjsonAcademico());
  public usuario;
  public name_usuario;
  public email = this._queriesService.getToken();
  public myFiles:string [] = [];
  public json: any = json;
  public array_Form: any = [{
    email: this.email,
    titulo: null,
    url_publicacion: [{
      url:null
    }]
  }]
  public array_enviar: any = {
    email: this.email,
    codpers: this.datos_academicos.COD_PERS,
    info_file: null
  };
  public urlRegistrar; 
  private urlLoginCas;
  public cargando;
  public msj: any;
  public msjEmail;
  public msjSend;
  public msj_error;
  public activePane: any = 't1';
  public via_ingreso = false;
  public msj_upload;
  public valida_titulos;

  constructor(
    private uploadService: FileuploadService,
    private _queriesService: QueriesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    public dataService: DataService
  ) {
    this.dataService.urls();
    this.urlRegistrar      = this.dataService.phpRegistrar;
    this.urlLoginCas       = this.dataService.urlLoginCas;
   }

  ngOnInit() {
    this.usuario = this._queriesService.getUser()
    let split = this.usuario.split(',');
    this.name_usuario = split[1];
    if(localStorage.getItem('array_Form_rpt')){ 
      this.array_Form = JSON.parse(localStorage.getItem('array_Form_rpt'));
    }else{
      localStorage.setItem('array_Form_rpt',JSON.stringify(this.array_Form));
      this.array_Form = JSON.parse(localStorage.getItem('array_Form_rpt')); 
    }
    
    this.toScroll('top');
    this.validaTitulos();
  }
  ngDoCheck(){
  }

  toScroll(pasos){
    document.getElementById(pasos).scrollIntoView({behavior: 'smooth'});
  }

  addForm(){
    localStorage.setItem('array_Form_rpt',JSON.stringify(this.array_Form));
    this.array_Form = JSON.parse(localStorage.getItem('array_Form_rpt'));
    this.array_Form.push({
      email: this.email,
      titulo: null,
      url_publicacion: [{url: null}],
    });
    localStorage.setItem('array_Form_rpt',JSON.stringify(this.array_Form));
    this.validaTitulos();
  }
  removeForm(i: number) {
    if(i==0){this.toScroll(i);}else{this.toScroll(i-1);}
    this.array_Form.splice(i, 1); 
    localStorage.setItem('array_Form_rpt',JSON.stringify(this.array_Form));
    this.array_Form = JSON.parse(localStorage.getItem('array_Form_rpt'));
    this.validaTitulos();
  }

  addUrl(i) {  
    localStorage.setItem('array_Form_rpt',JSON.stringify(this.array_Form));
    this.array_Form = JSON.parse(localStorage.getItem('array_Form_rpt'));
    this.array_Form[i].url_publicacion.push({
      url: null
    });
    localStorage.setItem('array_Form_rpt',JSON.stringify(this.array_Form));
  }
  removeUrl(i,a: number) {
    this.array_Form[i].url_publicacion.splice(a, 1); 
    localStorage.setItem('array_Form_rpt',JSON.stringify(this.array_Form));
    this.array_Form = JSON.parse(localStorage.getItem('array_Form_rpt'));
  }

  seleccionarArchivo(event) {
    let tipo = event.target.files[0].type;
    if(event.target.files[0].type != "text/javascript" && tipo != "application/x-msdownload"){
      /* for (var i = 0; i < event.target.files.length; i++) {    
          this.myFiles.push(event.target.files[i]);
      } */
      this.myFiles.push(event.target.files[0]);
      this.msj_upload = null;
    }else{
      this.msj_upload = "No se permite este tipo de archivo, por favor suba un documento válido";
    }
  }
  removeFile(i: number) {
    /* this.myFiles = null; */
    this.myFiles.splice(i, 1);
    this.msj_upload = null;
  } 

  validaTitulos(){
    let num = 0;
    for(let i in this.array_Form){
      if(this.array_Form[i].titulo){
        num = num + 1;
      }
    }
    if(num == this.array_Form.length){
      this.valida_titulos = true;
    }else{
      this.valida_titulos = false;
    }

  }

  // +++++++++++ ENVIO FORM +++++++++++++++
  submitPublica(form){ 
    this.activePane = 't2';
    this.toScroll('pasos');
    this.cargando     = true;
    const formData    = new FormData();
    let limpiar_array = this.array_Form;

    for(let i in limpiar_array){
      if(limpiar_array[i] == null || limpiar_array[i] == ''){
        delete limpiar_array[i];
      }
    }

    formData.append('array_datos', JSON.stringify(limpiar_array));
    formData.append('tipo', 'formulario');

        /* +++++ ENVIAR A API ++++ */
        this.http.post(this.urlRegistrar, formData)
        .subscribe(response => {
          if(response['code'] == 404){
            window.location.href = this.urlLoginCas;
            console.log("desde 404 respuesta login")
          }else if(response['httpCode'] == "200" || response['httpCode'] == "201"){
            this.array_Form = []; this.addForm();
            this.cargando = false;
            this.msj      = "success";
            this.msjSend  = "Proceso exitoso";
            
            if(response['httpCodeCorreo'] == "200" || response['httpCodeCorreo'] == "201"){
              this.msjEmail = "Se ha enviado email de confirmación a su cuenta";
            }else{
              this.msjEmail = "No hemos podido enviar email de confirmación a su cuenta";
            }
          }else{
            this.cargando   = false;
            this.msj        = "error";
            this.msjSend    = "Envío ha fallado."
            this.msj_error  = "¡Disculpa! Hemos enviado sus datos pero el servicio no logra recibirlos, inténtalo nuevamente.";
            console.log(response)
            console.log("desde error, sin datos desde api")
    }
        }, err => {
          this.msj        = "error";
          this.cargando   = false;
          this.msjSend    = "Envío ha fallado."
          this.msj_error  = "¡Disculpa! Hemos perdido la conexión con el servicio, inténtalo mas tarde";
          console.log(err);
          console.log("desde error, sin conección api")
        })
        
      
  }
  // +++++++++++ ENVIO FORM +++++++++++++++
  submitPublicaFile(form){ 
    this.activePane = 't2';
    this.toScroll('pasos');
    this.cargando     = true;
    const formData    = new FormData();
    let limpiar_array = this.array_enviar;

    for(let i in limpiar_array){
      if(limpiar_array[i] == null || limpiar_array[i] == ''){
        delete limpiar_array[i];
      }
    }

    formData.append('array_datos', JSON.stringify(limpiar_array));
    for (var i = 0; i < this.myFiles.length; i++) { 
      formData.append("file[]", this.myFiles[i]);
    }
    formData.append('tipo', 'archivo');

        /* +++++ ENVIAR A API ++++ */
        this.http.post(this.urlRegistrar, formData)
        .subscribe(response => {
          
          if(response['code'] == 404){
            window.location.href = this.urlLoginCas;
          }else if(response['httpCode'] == "200" || response['httpCode'] == "201"){
            this.myFiles = [];
            this.cargando = false;
            this.msj      = "success";
            this.msjSend  = "Proceso exitoso"

            if(response['httpCodeCorreo'] == "200" || response['httpCodeCorreo'] == "201"){
              this.msjEmail = "Se ha enviado email de confirmación a su cuenta";
            }else{
              this.msjEmail = "No hemos podido enviar email de confirmación a su cuenta";
            }
          }else{
            this.cargando   = false;
            this.msj        = "error";
            this.msjSend    = "Envío ha fallado."
            this.msj_error  = "¡Disculpa! Hemos enviado sus datos pero el servicio no logra recibirlos, inténtalo nuevamente.";
            console.log(response)
    }
        }, err => {
          this.msj        = "error";
          this.cargando   = false;
          this.msjSend    = "Envío ha fallado."
          this.msj_error  = "¡Disculpa! Hemos perdido la conexión con el servicio, inténtalo mas tarde";
          console.log(err);
        })
  }
}
