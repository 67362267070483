import { Component, OnInit, DoCheck } from '@angular/core';
import { QueriesService }             from '../../services/queries.service';
import { HttpClient }                 from '@angular/common/http';
import { Router, ActivatedRoute }     from '@angular/router';
import { DataService }                from 'src/app/services/data.service.js';
import json                           from '../../../assets/json/mis-publicaciones/mis-publicaciones.json';
import { Time } from '@angular/common';

@Component({
  selector: 'app-mis-publicaciones',
  templateUrl: './mis-publicaciones.component.html',
  styleUrls: ['./mis-publicaciones.component.css']
})
export class MisPublicacionesComponent implements OnInit, DoCheck {

  public json: any = json;
  public array_guardados:any = [];
  public array_en_espera:any = [];
  public array_confirmados:any = [];
  public estados = "confirmado";
  public urlLoginCas;
  public urlPhp;
  public urlGuardar;
  public urlImg;
  public urlHandle;
  public urldescargar;
  public data: any = [];
  public encontrado;
  public msj;
  public perfil:any = [];
  loggedIn: any = false;
  public usuario = this.queriesService.getUser()
  public name_usuario;
  public correo = this.queriesService.getToken();
  
  cargando;
  cargando_titalt;
  msj_guardar;
  public alertaElimina;

  /* ficha */
  public urlFicha;
  public modal_ficha = false;
  public tituloModal;
  public index_ficha;
  public foto;
  public embargo;
  public metadatos_simple:any = [];
  public error = false;
  public tipodata = "sencillo";

  public orden;
  public totalPage;
  public page;
  public npage: any = 1;
  public cantidadReg;
  public paginacion= [];
  public nextPage;
  public prevPage;
  public fechapub = "menos";
  public fechavin = "menos";
  public alf = "az";
  public ordenlista = "vinrecientes"

  /* email */
  urlEmail;
  response: any = {
    'show': false,
    'data': ''
  }
  public emailSolicitar = null;
  public nameTitAlt = null;
  message;
  num_toolguia;
  tituloAlt;
  public urltituloAlternativo;
  msjSend;
  msj_titalt;
  public estado_user_sipa;

  constructor(
    private queriesService: QueriesService,
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dataService: DataService
  ) { 
    
    this.dataService.urls();
    this.urlLoginCas          = this.dataService.urlLoginCas;
    this.urlImg               = this.dataService.php_img;
    this.urlPhp               = this.dataService.phpDiscovery; 
    this.urlGuardar           = this.dataService.phpGuardar; 
    this.urlHandle            = this.dataService.urlHandle;
    this.urlEmail             = this.dataService.phpEmail;
    this.urlFicha             = this.dataService.phpFicha;
    this.urldescargar         = this.dataService.phpDescargar; 
    this.urltituloAlternativo = this.dataService.phpTitAlt; 
  }

  ngOnInit() {
    // refresca página
    /* this.queriesService.getRefresh(); */

    this.estado_user_sipa = localStorage.getItem('estado_user_sipa')

    // Crear toolguia
    /* localStorage.setItem("toolguia", "p1")  MOMENTANEO DESACTIVADO */
    localStorage.removeItem("toolguia")
    
    // Armar nombre usuario
    let split = this.usuario.split(',');
    this.name_usuario = split[1];

    // JQuery ir arriba
    $('body, html').animate({
      scrollTop: '0px'
    }, 300);

    this.confirmados(1,'',1);
  }

  ngDoCheck(){
    if(!localStorage.getItem("toolguiaClose")){
      this.num_toolguia = localStorage.getItem("toolguia")
    }
  }
  
  toolguia(paso){
    if(paso=='p0'){
      localStorage.setItem("toolguiaClose", "false")
      this.num_toolguia = "p0"
    }else{
      localStorage.setItem("toolguia", paso)
    }
  }
  
  placeholder(){
    $('.label-default').addClass('label-top');
  }

  

  descargaGA(){
    this.data['tit'] = this.data['publics']['title'][0];
    this.router.navigate([ '/descarga-ficha/'+this.data['tit']]);
  }

  /* Solicitar contacto */
  submitContact(form, $event, title, handle) {
    $event.preventDefault();

    if(form.form.value)
      this.response.show = true;
      this.queriesService.queryGet(this.urlEmail+'nombreApellido='+this.usuario+'&mail='+this.correo+'&consulta='+this.message+'&handle='+this.urlHandle+handle+'&titulo='+title+'&tipo=correccion').then(
      (data) => {
        if(data['respuesta'] == true){
          this.response.show = true;
          this.response.text = 'Mensaje enviado';
          this.response.icono = 'check_circle';
          this.response.color = 'color-verde';
          //form.form.reset();
          this.message = "";
        }else{
          this.response.show = true;
          this.response.text = 'Ha ocurrido un problema. Por favor, intente más tarde';
          this.response.icono = 'highlight_off';
          this.response.color = 'texto-dg-rojo';
        }
      },
      (error) => {
        console.log(error);
        this.response.show = true;
        this.response.text = 'Ha ocurrido un problema. Por favor, intente más tarde';
        this.response.icono = 'highlight_off';
        this.response.color = 'texto-dg-rojo';
      }
    );
  }

  tit_alt(id,i){
    this.cargando_titalt = true;
    let array_titalt = {
      uuid: id,
      titulo: this.tituloAlt,
      accion: "alternativo"
    }

    const formData = new FormData();
    formData.append('array_datos_alternativo', JSON.stringify(array_titalt));

    this.http.post(this.urltituloAlternativo, formData)
    .subscribe(response => {
      this.cargando_titalt = false;

      if(response['respuesta'] == true){
           
              this.nameTitAlt=null; 
              this.msj_titalt=null // "success";
              this.array_confirmados[i].title = this.tituloAlt;
      }else{
        this.msj_titalt = "error";
        console.log("se conectó pero no trajo httpCode 201");
        console.log(response)
      }
    }, err => {
      this.msj_titalt = "error";
      this.cargando_titalt = false;
      console.log("¡Disculpa! Hemos perdido la conexión, inténtalo mas tarde");
      console.log(err);
    })
  }
  abreModal_ficha(i,handle, titulo){ 
    this.modal_ficha = true;
    this.index_ficha = i;
    this.tituloModal = "";

    this.queriesService.queryGet(this.urlFicha +'handle=' + handle).then((data) => {
      if(data['code'] == 404){ /* Verifica sesion */
        window.location.href = this.urlLoginCas;
      }else{
        if(data['response']['docs'].length>0){
          this.error = false;
          this.data['publics'] = data['response']['docs'][0];

          // Cambiar titulo por alternativo
          this.data['publics']['title'][0] = titulo;

          //data['miniatura'];
          if(data['miniatura']){
            this.foto = this.urlImg +"/"+data['response']['docs'][0]['handle']+"/"+data['miniatura'];
          }else{
            /* this.foto = "assets/img/logo_uc_linea.png"; */
            this.foto = null;
          }

          // Archivo en embargo
          if(data['embargo'] == 200 || data['embargo'] == 201){
              this.embargo = false;
          }else{
              this.embargo = true;
          }

          // Construye tabla metadatos
          let list = this.data['publics'];
          for(let i in list){
            if (i.substr(0,10)=='indexacion' || i.substr(0,16)=='sipa.fechainicio' || i.substr(0,17)=='sipa.fechatermino' || i.substr(0,17)=='sipa.tipocreacion' || i.substr(0,3)  == 'dc.' && i.indexOf('_') == -1 ){  
              /* cambiar nombres */
              var alias = i;
              var link = false;
              var mostrar = true;
              
              if(i == "dc.areadearte"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              } 
              if(i == "dc.article.number"){
                alias          = "Número de artículo"; 
                link           = false;
              } 
              if(i == "dc.concurso"){
                alias          = "Concurso Arte y Cultura"; 
                link           = false;
              } 
              if(i == "dc.conservacion.estado"){
                alias          = "Conservación"; 
                link           = false;
              }    
              if(i == "dc.contributor"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              } 
              if(i == "dc.contributor.advisor"){
                alias          = "Profesor guía"; 
                link           = false;
              } 
              if(i == "dc.contributor.author"){
                alias          = "Autor";
                link           = false;
              } 
              if(i == "dc.contributor.participante"){
                alias          = "Participante";
                link           = false;
              } 
              if(i == "dc.contributor.other"){
                alias          = "Otro autor"; 
                link           = false;
              }  
              if(i == "dc.contributor.editor"){
                alias          = "Editor";
                link           = false;
              }  
              if(i == "dc.contributor.illustrator"){
                alias          = "Ilustrador";
                link           = false;
              }  
              if(i == "dc.coverage.spatial"){
                alias          = "Cobertura geográfica";
                link           = false;
              } 
              if(i == "dc.coverage.temporal"){
                alias          = "Cobertura temporal";
                link           = false;
              } 
              if(i == "dc.creator"){
                alias          = "nulo";
                link           = false;
                mostrar        = false;
              } 
              if(i == "dc.cultura"){
                alias          = "Cultura";
                link           = false;
              } 
              if(i == "dc.date"){
                alias          = "Fecha de embargo";
                link           = false;
                mostrar        = false;
              }
              if(i == "dc.date.accessioned"||i == "dc.date.available" || i == "dc.date.submitted"){ /* eliminar ??? */
                alias          = "nulo";
                link           = false;
                mostrar        = false;
              }
              if(i == "dc.date.concesion"){
                alias          = "Fecha de concesión";
                link           = false;
              }
              if(i == "dc.date.copyright"){
                alias          = "nulo";
                link           = false;
                mostrar        = false;
              }
              if(i == "dc.date.created"){
                alias          = "Fecha de creación";
                link           = false;
              }
              if(i == "dc.date.issued"){
                alias          = "Fecha de publicación";
                link           = false;
              }
              if(i == "dc.date.updated"){
                alias          = "nulo";
                link           = false;
                mostrar        = false;
              }
              if(i == "dc.description"){
                alias          = "Nota"; 
                link           = false;
              } 
              if(i == "dc.description.abstract"){
                alias          = "Resumen"; 
                link           = false;
              } 
              if(i == "dc.description.funder"){
                alias          = "Financiamiento"; 
                link           = false;
              } 
              if(i == "dc.description.provenance"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              } 
              if(i == "dc.description.sponsorship"){
                alias          = "Patrocinio"; 
                link           = false;
              } 
              if(i == "dc.description.statementofresponsibility"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              } 
              if(i == "dc.description.tableofcontents"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              } 
              if(i == "dc.description.uri"){
                alias          = "Video"; 
                link           = true;
              } 
              if(i == "dc.description.version"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              } 
              if(i == "dc.destinatario"){
                alias          = "Destinatario"; 
                link           = false;
              } 
              if(i == "dc.donante"){
                alias          = "Donante"; 
                link           = false;
              } 
              if(i == "dc.estado.patente"){
                alias          = "Estado de patentamiento"; 
                link           = false;
              } 
              if(i=="dc.estamento1" ||i=="dc.estamento2" ||i=="dc.estamento3" ||i=="dc.estamento4"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              } 
              if(i=="dc.estamento.1" ||i=="dc.estamento.2" ||i=="dc.estamento.3" ||i=="dc.estamento.4"){
                alias          = "nulo"; 
                link           = false;
              } 
              if(i == "dc.format"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              } 
              if(i == "dc.format.extent"){
                alias          = "Paginación"; 
                link           = false;
              } 
              if(i == "dc.format.medium"){
                alias          = "Medio"; 
                link           = false;
              } 
              if(i == "dc.format.mimetype"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              } 
              if(i == "dc.fuente.origen"){ 
                alias          = "Fuente"; 
                link           = false;
                mostrar        = false;
              } 
              if(i == "dc.funcion"){
                alias          = "Función"; 
                link           = false;
              } 
              if(i == "dc.identifier"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              } 
              if(i=="dc.identifier.codUA"||i=="dc.identifier.codUA1"||i=="dc.identifier.codUA2"||i=="dc.identifier.codUA3"||i=="dc.identifier.codUA4"||i=="dc.identifier.codUA5"||i=="dc.identifier.codUA6"||i=="dc.identifier.codUA7"||i=="dc.identifier.codUA8"||i=="dc.identifier.codUA9"||i=="dc.identifier.codUA10"){
                alias          = "Unidad académica"; 
                link           = false;
              } 
              if(i == "dc.identifier.citation"){
                alias          = "Cómo citar este documento"; 
                link           = false;
              } 
              if(i == "dc.identifier.concesion"){
                alias          = "Número de concesión"; 
                link           = false;
              } 
              if(i == "dc.identifier.converisid"){
                alias          = "Id de publicación en Converis"; 
                link           = false;
              } 
              if(i == "dc.identifier.dialnetid"){
                alias          = "Id de publicación en Dialnet"; 
                link           = false;
              } 
              if(i == "dc.identifier.doi"){
                alias          = "DOI"; 
                link           = true;
              } 
              if(i == "dc.identifier.eisbn"){
                alias          = "ISBN electrónico"; 
                link           = false;
              } 
              if(i == "dc.identifier.eissn"){
                alias          = "ISSN electrónico"; 
                link           = false;
              } 
              if(i == "dc.identifier.govdoc"){
                alias          = "Número de solicitud"; 
                link           = false;
              } 
              if(i == "dc.identifier.isbn"){
                alias          = "ISBN"; 
                link           = false;
              } 
              if(i == "dc.identifier.ismn"){
                alias          = "nulo"; 
                link           = false;
                mostrar        = false;
              } 
              if(i == "dc.identifier.issn"){
                alias          = "ISSN";
                link           = false; 
              } 
              if(i == "dc.identifier.other"){
                alias          = "Identificador del recurso";
                link           = false; 
              }
              if(i == "dc.identifier.pubmedid"){
                alias = "Id de publicación en Pubmed";
                link = false; 
              } 
              if(i == "dc.identifier.scieloid"){
                alias = "Id de publicación en Scielo";
                link = false; 
              } 
              if(i == "dc.identifier.scopusid"){
                alias = "Id de publicación en Scopus";
                link = false; 
              } 
              if(i == "dc.identifier.sici"){
                alias = "nulo";
                link = false; 
                mostrar = false;
              } 
              if(i == "dc.identifier.slug"){
                alias = "nulo";
                link = false; 
                mostrar = false;
              } 
              if(i == "dc.identifier.UA"){
                alias = "Unidad Académica";
                link = false; 
              } 
              if(i == "dc.issue.numero"){
                alias = "Número de publicación"; 
                link = false;
              }
              if(i == "dc.identifier.orcid"||i == "dc.identifier.orcid1"||i == "dc.identifier.orcid2"||i == "dc.identifier.orcid3"||i == "dc.identifier.orcid4"||i == "dc.identifier.orcid5"||i == "dc.identifier.orcid6"||i == "dc.identifier.orcid7"||i == "dc.identifier.orcid8"||i == "dc.identifier.orcid9"||i == "dc.identifier.orcid10"){
                alias = "Número ORCID";
                link = false;
              } 
              if(i == "dc.identifier.uri"){
                alias = "Enlace";
                link = true;
              } 
              if(i == "dc.identifier.wosid"){
                alias = "Id de publicación en WoS";
                link = false;
              } 
              if(i == "dc.information.autoruc"){
                alias = "Información del autor UC";
                link = false;
                mostrar = false;
              } 
              if(i == "dc.language"){
                alias = "Idioma";
                link = false;
                mostrar = false;
              } 
              if(i == "dc.language.iso"){
                alias = "nulo"; // Código ISO del idioma de la publicación
                link = false;
              } 
              if(i == "dc.language.rfc3066"){
                alias = "nulo";
                link = false;
                mostrar = false;
              } 
              if(i == "dc.localidad"){
                alias = "Localidad";
                link = false;
              } 
              if(i == "dc.lugar.publicacion"){
                alias = "Lugar de publicación";
                link = false;
              } 
              if(i == "dc.material"){
                alias = "Material";
                link = false;
              } 
              if(i == "dc.nota"){
                alias = "nulo";
                link = false;
                mostrar = false;
              } 
              if(i == "dc.nota.acceso"){
                alias = "nulo";
                link = false;
                mostrar = false;
              } 
              if(i == "dc.pagina.final"){
                alias = "Página final"; 
                link = false;
              } 
              if(i == "dc.pagina.inicio"){
                alias = "Página inicio"; 
                link = false;
              } 
              if(i == "dc.pagina.pais"){
                alias = "Pais"; 
                link = false;
              } 
              if(i == "dc.pais"){
                alias = "Pais"; 
                link = false;
              } 
              if(i == "dc.presentacion"){
                alias = "Tipo de presentación de patente"; 
                link = false;
              } 
              if(i == "dc.provenance"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.publisher"){
                alias = "Editorial"; 
                link = false;
              } 
              if(i == "dc.region"){
                alias = "Región"; 
                link = false;
              } 
              if(i == "dc.relation"){
                alias = "Publicación relacionada"; 
                link = false;
              }
              if(i == "dc.relation.haspart"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              }
              if(i == "dc.relation.hasversion"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              }
              if(i == "dc.relation.isbasedon"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              }
              if(i == "dc.relation.isformatof"){
                alias = "Se encuentra en"; 
                link = false;
              } 
              if(i == "dc.relation.ispartofseries"){
                alias = "Serie"; 
                link = false;
              } 
              if(i == "dc.relation.isreferencedby"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.relation.isreplacedby"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.relation.isversionof"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.relation.replaces"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.relation.requires"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.relation.ispartof"){
                alias = "Publicado en / Colección"; 
                link = false;
              } 
              if(i == "dc.revista"){
                alias = "Revista"; 
                link = false;
              } 
              if(i == "dc.rights"){
                alias = "Derechos"; 
                link = false;
              } 
              if(i == "dc.rights.access"){
                alias = "Tipo de Acceso"; 
                link = false;
              } 
              if(i == "dc.rights.holder"){
                alias = "Titular de los derechos"; 
                link = false;
              } 
              if(i == "dc.rights.license"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.rights.uri"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.rubro"){
                alias = "Rubro"; 
                link = false;
              } 
              if(i == "dc.solicitante"){
                alias = "Nombre de solicitante"; 
                link = false;
              } 
              if(i == "dc.source"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.source.uri"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.relation.uri"){
                alias = "Enlace relacionado";
                link = true;
              } 
              if(i == "dc.subject.classification"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.subject.ddc"){
                alias = "Número de clasificación Dewey"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.subject.dewey"){
                alias = "Temática"; 
                link = false;
              } 
              if(i == "dc.subject.lcc"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.subject.lcsh"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.subject.mesh"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.subject.other"){
                alias = "Materia"; 
                link = false;
              } 
              if(i == "dc.tecnica"){
                alias = "Técnica"; 
                link = false;
              } 
              if(i == "dc.territorialidad"){
                alias = "Territorialidad"; 
                link = false;
              } 
              if(i == "dc.subject"){
                alias = "Palabra clave"; 
                link = false;
              } 
              if(i == "dc.title.alternative"){
                alias = "Otro título"; 
                link = false;
              } 
              if(i == "dc.title"){
                /* list[i] = title;
                i = "title"; */
                
                alias = "Título"; 
                link = false;
              } 
              if(i == "dc.type"){
                alias = "Tipo de documento"; 
                link = false;
              } 
              
              if(i == "dc.type.qualificationlevel"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.ubicacion"){
                alias = "Ubicación"; 
                link = false;
              } 
              if(i == "dc.uc.hito"){
                alias = "Hito UC"; 
                link = false;
              } 
              if(i == "dc.volumen"){
                alias = "Volumen"; 
                link = false;
              } 
              if(i == "dc.zcode"||i == "dc.zcode.1" || i == "dc.zcode.2" || i == "dc.zcode.3"|| i == "dc.zcode.4"|| i == "dc.zcode.5"|| i == "dc.zcode.6"|| i == "dc.zcode.7"|| i == "dc.zcode.8"|| i == "dc.zcode.9"|| i == "dc.zcode.10"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "sipa.fechainicio"){
                alias = "Fecha de inicio"; 
                link = false;
              } 
              if(i == "sipa.fechatermino"){
                alias = "Fecha de término"; 
                link = false;
              } 
              /*
              if(i == "sipa.otra.index"){
                alias = "Indexación"; 
                link = false;
              }  */
              if(i == "indexacion"){
                alias = "Indexación"; 
                link = false;
              } 
              if(i == "sipa.afi.uc"||i == "sipa.codpersvinculados" || i == "sipa.fecha.validacionbiblio" || i == "sipa.fecha.validavinculacion"|| i == "sipa.fecha.vinculacion"|| i == "sipa.identifier.ha"|| i == "sipa.identifier.propintelectual"|| i == "sipa.identifier.repositoriouc"|| i == "sipa.identifier.solicpropintelectual"|| i == "sipa.trazabilidad"|| i == "sipa.validacionbiblio"){
                alias = "nulo"; 
                link = false;
                mostrar = false;
              } 
              if(i == "dc.description.auspiciador"){ /* Agregado nuevo el 29 may 2023 */
                alias = "Institución auspiciadora"; 
                link = false;
              } 
              if(i == "dc.description.caracterobra"){ /* Agregado nuevo el 29 may 2023 Se cambia nombre*/
                alias = "Carácter de la curatoría"; 
                link = false;
              } 
              if(i == "dc.tipodeobra"){ /* Agregado nuevo el 29 may 2023 */
                alias = "Tipo de Obra"; 
                link = false;
                mostrar = true;
              } 
              if(i == "sipa.tipocreacion"){ /* Agregado nuevo el 29 may 2023 */
                alias = "Tipo de creación"; 
                link = false;
              } 
              if(i == "dc.rol"){ /* Agregado el 29 may 2023 */
                alias = "Rol"; 
                link = false;
              } 

              /* CAMBIO NOMBRE METADATOS REGISTRO COMPLETO  */
              if(i == "indexacion"){ /* i == "dc.title" ||  */
                /* if(i == "dc.title"){
                  this.metadatos_simple.push( { "metadato": alias, "cod":'title', "valor": [titulo], "link":link, "mostrar":mostrar });
                } */
                if(i == "indexacion"){
                  this.metadatos_simple.push( { "metadato": alias, "cod":'sipa.index', "valor": list[i], "link":link, "mostrar":mostrar });
                }
              }else{
                this.metadatos_simple.push( { "metadato": alias, "cod":i, "valor": list[i], "link":link, "mostrar":mostrar });
              }

            }
          }

          //Ordena
          this.metadatos_simple.sort((a, b) => (a.metadato > b.metadato) ? 1 : -1);

        }else{
          this.error = true;
        }
      }
    });

  }
  cerrarModal_ficha(){
    this.metadatos_simple = [];
    this.modal_ficha = false;
  }
  metadatas(reg){
    this.tipodata = reg;
  }
  confirmados(page,filtro, orden){
    this.cargando = true;
    this.msj = null;
    var acciones;

    this.npage = page;
    localStorage.setItem('page',page );
    page = (page-1)*20;
    this.orden = orden;
    const formData = new FormData();

    /* Armo acciones */
    acciones = {accion: "buscar", id: "", email: this.correo, validaBiblio: "Validado", start: this.npage, orden: this.orden , handle: ""}

    /* Entrgo array */
    formData.append('array_acciones', JSON.stringify(acciones));
     
    /* +++++ CARGAR EN BBDD ++++ */

    this.http.post(this.urlGuardar, formData)
      .subscribe(response => {
        this.cargando = false;
        if(response['code'] == 404){ /* Verifica sesion */
          window.location.href = this.urlLoginCas;
        }else{
          if(response){  
            this.array_confirmados = response['Doc'];

            // agregar a confirmados tambien abreModal_ficha para mostrar los datos de la fich
          
            // PAGINACIÓN
            this.totalPage = response['RecordsFound'];
            this.cantidadReg = 20;
            this.page  = Math.ceil(this.totalPage / this.cantidadReg); 

            this.paginacion = []; // Dejar vacío para volver a crear loop con cada consulta
            for(let i=1; i<=this.page; i++){
              if(i <= 5){
                if(this.npage>5){
                  this.paginacion.push(i+(this.npage-5));
                }else{
                  this.paginacion.push(i);
                }
              }
            }
            if(this.npage>=2){
              this.prevPage = this.npage-1;
            }else{
              this.prevPage = 1;
            }
            if(this.npage<this.page){
              this.nextPage = this.npage+1;
            }else{
              this.nextPage = this.page;
            }
            // Fin paginación

          }else{
            this.array_confirmados = [];
            this.msj = "No existen documentos en espera"
            console.log("no hay registros");  
          }
        }
    }, err => {
      this.cargando = false;
      this.msj = "¡Disculpa! Hemos perdido la conexión, inténtalo mas tarde"
      console.log(err);
    })
  }
}

